/* Roadmap.css */
.roadmap-container {
    position: relative;
    width: 100%;
    background-color: #eee; /* Farbe der Hintergrundleiste */
    margin-top: 40px; /* Abstand oben */
    margin-bottom: 20px; /* Abstand unten */
    padding: 1px; /* Minimaler Innenabstand zur optischen Trennung */
}

.roadmap-progress {
    position: absolute;
    height: 20px; /* Höhe der Fortschrittsleiste */
    background-color: var(--primary); /* Farbe der Fortschrittsleiste */
    transition: width 0.3s ease-in-out;
    top: 50%;
    transform: translateY(-50%);
}

.roadmap-steps {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 170px; /* Erhöhte Höhe für Zickzack-Layout */
}

.step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%; /* Volle Höhe des Containers nutzen */
    flex: 1; /* Gleichmäßige Verteilung */
    padding: 0 0 0 0px; /* Zusätzlicher horizontaler Raum zwischen den Elementen */
    padding-left: 45px;
    padding-right: 45px;
}

.step, .date {
    position: absolute; /* Absolute Positionierung innerhalb des Containers */
    width: auto; /* Automatische Breitenanpassung */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    white-space: nowrap; /* Verhindert das Umbruch der Texte */
    overflow: hidden;
    text-overflow: ellipsis; /* Zeigt Ellipsen bei zu langem Text */
    padding: -2px;
}

.step {
    top: 20%; /* Positionierung der Schritte oben im Container */
    font-size: 0.85rem; /* Kleinere Schriftgröße für die Schritte */
    color: #333; /* Dunklere Textfarbe für bessere Lesbarkeit */
    line-height: 1.4; /* Erhöhte Zeilenhöhe zur Vermeidung von Abschneiden */
}

.date {
    bottom: 10%; /* Positionierung der Daten unten im Container */
    font-size: 0.75rem; /* Kleinere Schriftgröße für die Daten */
    color: #666; /* Hellere Textfarbe */
}

.completed {
    font-weight: bold; /* Hervorhebung abgeschlossener Schritte */
}

.step-container:first-child {
    padding-left: 0px; /* Erhöht den rechten Padding des letzten Containers */
}

.step-container:last-child {
    padding-right: 5px; /* Erhöht den rechten Padding des letzten Containers */
}

@media (max-width: 340px) {

    .step-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .step-container:nth-child(odd) .step,
    .step-container:nth-child(odd) .date {
        bottom: 30%; /* Positioniert ungerade Elemente weiter unten */
    }

    .step-container:nth-child(even) .step,
    .step-container:nth-child(even) .date {
        top: 30%; /* Positioniert gerade Elemente weiter oben */
    }

    .step, .date {
        font-size: 0.5rem; /* Kleinere Schriftgröße für Schritte und Daten */
        position: absolute;
        left: 10%;
        text-align: center;
    }

    .step {
        transform: translateY(-70%); /* Zentriert den Text im Container */
        margin-bottom: 40px;
        margin-top: 3px;
    }
    .date {
        transform: translateY(70%); /* Zentriert den Text im Container */
        margin-top: 50px;
    }

    .roadmap-progress {
        top: 50%; /* Setzt die Fortschrittsleiste in die Mitte des Containers */
        transform: translateY(-50%);
    }

    .step-container:last-child .date, .step-container:last-child .step {
        right: 1px; /* Stellt sicher, dass das Datum nicht über die Box hinausragt */
        left: auto; /* Hebt die bisherige horizontale Ausrichtung auf */
    }
}

@media (min-width: 341px) and (max-width: 680px) {
    .step-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .step-container:nth-child(odd) .step,
    .step-container:nth-child(odd) .date {
        bottom: 30%; /* Positioniert ungerade Elemente weiter unten */
    }

    .step-container:nth-child(even) .step,
    .step-container:nth-child(even) .date {
        top: 30%; /* Positioniert gerade Elemente weiter oben */
    }

    .step, .date {
        font-size: 0.55rem; /* Kleinere Schriftgröße für Schritte und Daten */
        position: absolute;
        left: 10%;
        text-align: center;
    }

    .step {
        transform: translateY(-70%); /* Zentriert den Text im Container */
        margin-bottom: 40px;
        margin-top: 3px;
    }
    .date {
        transform: translateY(70%); /* Zentriert den Text im Container */
        margin-top: 50px;
    }

    .roadmap-progress {
        top: 50%; /* Setzt die Fortschrittsleiste in die Mitte des Containers */
        transform: translateY(-50%);
    }

    .step-container:last-child .date, .step-container:last-child .step {
        right: 1px; /* Stellt sicher, dass das Datum nicht über die Box hinausragt */
        left: auto; /* Hebt die bisherige horizontale Ausrichtung auf */
    }
}

@media (min-width: 681px) and (max-width: 1024px) {
    .step, .date {
        font-size: 0.75rem; /* Angepasste Schriftgröße für Tablets */
    }

}

@media (min-width: 1025px) {
    .step, .date {
        font-size: 0.85rem; /* Größere Schriftgröße für Desktop */
    }
    .step {
        margin-top: -20px;
    }
}


/* ---------------- Navbar and burger menu ------------------ */
/* nav {
    width: 100%;
    height: 5em;
    background-color: rgb(37, 39, 53);
    display: flex;
    justify-content: flex-start;
    padding: 1em;
} */

.burger-menu {
    height: 100%;
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 5px;
    margin-right: -25px;
}

.burger-bar {
    width: 2.5em;
    height: 0.5em;
    background-color: var(--secondary);
    border-radius: 0.5em;
    margin-bottom: 0.3em;
}

.menu {
    width: 100%;
    max-width: 300px; /* Setzt eine Mindestbreite für das Menü */
    border-bottom-left-radius: 2em;
    display: flex;
    flex-direction: column; /* Änderung hier */
    background-color: var(--primary);
    position: absolute;
    padding-right: 35px;
    padding-bottom: 25px;
    top: 60px; /* Abstand vom oberen Bildschirmrand */
    right: 0px; /* Abstand vom linken Bildschirmrand */
    z-index: -1;
}

.link-item {
    display: flex;
    align-items: center; /* Vertikal zentrieren */
  }
  
  .link-item-icon {
    width: 20px; /* Ändere die gewünschte Breite des Bildes */
    height: auto;
    margin-right: 10px; /* Abstand zwischen Icon und Text */
  }

/* ------------- sliding menu ------------------ */
.hidden {
    display: none;
}

.visible {
    display: inherit;
}

/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1){
    transform: rotate(45deg) translate(0.35em, 1em);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2){
    transform: scale(0.01);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3){
    transform: rotate(-45deg) translate(0.15em, -0.85em);
    transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}
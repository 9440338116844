.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 40em) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

}

@media screen and (max-width: 380px) {
  .instagram-logo {
    max-width: 32px;
    max-height: 32px;
  }
}

.svg-icon {
  cursor: pointer;
  margin-left: 20px; /* Fügt einen Abstand von 10px zu jedem Icon hinzu */
}

@media screen and (max-width: 380px) {
  .svg-icon {
    transform: scale(0.8); /* Verkleinert um 20% */
    margin-left: 10px;
}}




.container {
  display: flex;
  align-items: center;
}
svg {
  margin-right: 10px; /* Adjust the value to add space between the image and text */
}

.App-header {
  background-color: var(--secondary);;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--secondary);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.PDFoptiontext{
  width: 50%;
  margin: 0 auto;
  border: 1px solid #750606;
}

.MuiTextField-root {
  margin: 20px;
  padding: 20px;
}
.MuiDateField-root{
  margin: 10px;
  padding: 10px;
}
.MuiTableContainer-root{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.MuiButton-root{
  margin: 2px;
}
button.button {
  margin: 10px;
}


@media (max-width: 900px) {
  .MuiButton-root {
    font-size: 10; 
  }
}


.material-symbols-outlined {
  /* Hier kannst du deine allgemeinen Stile für die Icons festlegen */

  @media (max-width: 380px) {
    /* Hier werden die Stile für Bildschirmbreiten kleiner als 380px überschrieben */
    font-size: 16px; /* Beispiel: Verkleinere die Schriftgröße auf 12px */
    /* Füge hier weitere Stile hinzu, um die Icons zu verkleinern */
  }
}



.plantstablewidth {
  max-width: 50px;
  min-width: 15px;
}

.white-label {
  color: white !important;
}
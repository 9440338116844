
:root {
  --primary: #8ec68d;  /*Original 8ec68d, 8fb66d for van gogh green RAL 130 70 40, b3cf99 for pastel green RAL 130 80 30 */
  --secondary: #282c34;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url(../public/fonts/Barlow/Barlow-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body,html {
  margin: 0;
  padding: 0;
  height: 100vh;
  color: var(--secondary);
  font-family: 'Barlow';
  background: #f2f2f2;
  overflow-y: none;
  overflow-x: hidden;
  /* background: url('./images/Background/Background-2.jpg') center/cover no-repeat; */
}
nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: right;
}
nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}
/* 
h1 {
  color: #000000;
  text-align: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
} */

nav Button {
  justify-content: center;
  align-items: left;
  text-align: left;
  padding: 10px;
}

.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.page-home button {
  padding: 10px;
  margin: 10px;
  justify-content: right;
  align-items: center;
}

.page-home h1 {
  font-size: 50pt;
  font-family: 'Barlow';
  text-align: center;
}

.page-home p {
  text-align: left;
  font-family: 'Barlow';
  font-size: 18pt;
  padding-left: 30%;
  padding-right: 30%;
}

.page-beitraege .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-beitraege h1 {
  font-size: 50pt;
  font-family: 'Barlow';
  text-align: center;
}

.page-beitraege p {
  text-align: left;
  font-family: 'Barlow';
  font-size: 18pt;
  padding-left: 0%;
  padding-right: 0%;
}

.page-beitraege img {
  max-width: 450px;
  float: left;
}



/* forms */
form {
  background: #fff;
  padding: 20px;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 6px;
}
form input, form textarea, form select {
  display: block;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  margin: 3px 0 px 0;
}
form button {
  background: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
}


.box-card .buttons {
  text-align: right;
}
.box-card i {
  color: #bbb;
  margin-left: 10px;
  font-size: 1.2em;
  padding: 6px;
  background: #eee;
  border-radius: 50%;
  cursor: pointer;
  fill: 100;
}

/* order-by buttons */
.order-by button {
  margin-right: 10px;
  background: var(--primary);
  color: #fff;
  border: 0;
  padding: 4px 8px;
  border-radius: 6px;
  font-family: "Poppins";
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  background: url('./images/Background/Background-2.webp') center/cover no-repeat;
}

header {
  /* Streckt den Header so weit wie möglich aus, um den Inhalt zu füllen */
  flex-grow: 1;
}

.footer-content {
  /* Fixiert den Footer am unteren Rand */
  margin-top: auto;
  padding-top: 20px;
}


.pdfviewer {
  height: 580px;
  width: 100%;
}

.container {
  width: 100vw; /* 100% der Bildschirmbreite */
  height: 100vh; /* 100% der Bildschirmhöhe */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Positionierung relativ zum Viewport */
  top: 0; /* Am oberen Bildschirmrand positionieren */
  left: 0; /* Am linken Bildschirmrand positionieren */
}

.content {
  position: relative; /* Positionierung relativ zum übergeordneten Container (.container) */
  width: 100%; /* Breite des Containers, kann angepasst werden */
  height: 100%; /* Höhe des Containers, kann angepasst werden */
}

.background {
  width: 250%; /* 100% der Breite des Containers */
  height: 250%; /* 100% der Höhe des Containers */
  /* background-image: url('./images/Background/cannabis_leaf.png'); */ /* Pfad zur Bilddatei des Hanfblatts */
  background-size: contain; /* Das Bild wird so skaliert, dass es den ganzen Bereich abdeckt, ohne sein Seitenverhältnis zu ändern */
  background-repeat: no-repeat; /* Bildwiederholung deaktivieren */
}

.text {
  position: absolute; /* Absolute Positionierung relativ zum übergeordneten Container (.content) */
  top: 50%; /* Oben-Abstand 50% des Containers */
  left: 50%; /* Links-Abstand 50% des Containers */
  transform: translate(-50%, -50%); /* Zentrierung des Textes */
  color: white; /* Textfarbe */
}

.TabListContainer {
  position: relative;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}


